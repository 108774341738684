
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatBadge1(badge, badgeIndex) {
                    return _createElement('div', {
                        'className': 'badge cm_badge cm_badge__' + this.toKebabCase(badge),
                        'key': badge
                    }, '\n          ', badge, '\n        ');
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'halo-item' }, _createElement('div', {
                    'className': 'product-card',
                    'data-product-card': true,
                    'data-product-id': 'product-' + this.id,
                    'data-id': this.id
                }, _createElement('div', { 'className': 'product-image' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'product_badges' },
                    this.on_sale ? _createElement('div', {
                        'className': 'badge sale-badge',
                        'key': '205'
                    }, 'Sale') : null,
                    this.out_of_stock ? _createElement('div', {
                        'className': 'badge soldOut-badge',
                        'key': '275'
                    }, 'Sold out') : null,
                    this._badges ? _map(this._badges, repeatBadge1.bind(this)) : null
                ]), _createElement('a', {
                    'className': 'product-link ' + (this.image2 ? 'image-swap' : ''),
                    'href': this.url,
                    'aria-label': 'link'
                }, _createElement('img', {
                    'className': 'lazyautosizes lazyloaded' + (this.image2 ? ' image-one' : ''),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'image-two lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '894'
                }) : null), _createElement('a', {
                    'className': 'product-quickview',
                    'aria-label': 'quickview',
                    'id': this.handle,
                    'data-quickview': true,
                    'aria-describedby': 'a11y-external-message'
                }, '\n        Quick View\n      ')), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'product-detail' }, _createElement('div', { 'className': 'product-vendor' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h4', { 'className': 'product-title' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'aria-label': 'title'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', {
                    'className': 'product-price' + (this.on_sale ? ' price--on-sale' : ''),
                    'data-price': true
                }, this.on_sale ? [
                    _createElement('span', {
                        'className': 'price-item price-item--regular',
                        'data-regular-price': true,
                        'key': '18531'
                    }, '\n              ', this.formatPrice(this.compare_at_price), '\n            '),
                    _createElement('span', {
                        'className': 'price-item price-item--sale',
                        'data-sale-price': true,
                        'key': '18533'
                    }, this.formatPrice(this.price))
                ] : null, !this.on_sale ? [
                    this.price_varies ? _createElement('span', { 'key': '21881' }, 'From: ') : null,
                    _createElement('span', {
                        'className': 'price-item price-item--regular',
                        'data-regular-price': true,
                        'key': '21883'
                    }, '\n              ', this.formatPrice(this.price), '\n            ')
                ] : null, this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item--set',
                    'key': '2460'
                }, '\n            Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n          ') : null, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '26500'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '3058'
                }) : null, _createElement('a', {
                    'className': 'wishlist',
                    'aria-label': 'wishlist',
                    'data-icon-wishlist': true,
                    'href': '#',
                    'data-product-handle': this.handle,
                    'data-id': this.id
                }, _createElement('svg', {
                    'viewBox': '0 0 1024 1024',
                    'className': 'icon icon-heart'
                }, _createElement('path', {
                    'className': 'path1',
                    'd': 'M486.4 972.8c-4.283 0-8.566-1.074-12.434-3.222-4.808-2.67-119.088-66.624-235.122-171.376-68.643-61.97-123.467-125.363-162.944-188.418-50.365-80.443-75.901-160.715-75.901-238.584 0-148.218 120.582-268.8 268.8-268.8 50.173 0 103.462 18.805 150.051 52.952 27.251 19.973 50.442 44.043 67.549 69.606 17.107-25.565 40.299-49.634 67.55-69.606 46.589-34.147 99.878-52.952 150.050-52.952 148.218 0 268.8 120.582 268.8 268.8 0 77.869-25.538 158.141-75.901 238.584-39.478 63.054-94.301 126.446-162.944 188.418-116.034 104.754-230.314 168.706-235.122 171.376-3.867 2.149-8.15 3.222-12.434 3.222zM268.8 153.6c-119.986 0-217.6 97.614-217.6 217.6 0 155.624 120.302 297.077 221.224 388.338 90.131 81.504 181.44 138.658 213.976 158.042 32.536-19.384 123.845-76.538 213.976-158.042 100.922-91.261 221.224-232.714 221.224-388.338 0-119.986-97.616-217.6-217.6-217.6-87.187 0-171.856 71.725-193.314 136.096-3.485 10.453-13.267 17.504-24.286 17.504s-20.802-7.051-24.286-17.504c-21.456-64.371-106.125-136.096-193.314-136.096z'
                })))), this.body_html && this.view === '1' ? _createElement('div', mergeProps({
                    'className': 'product-description',
                    'key': '4496'
                }, { dangerouslySetInnerHTML: { __html: this.body_html } })) : null, _createElement('div', { 'className': 'product-action' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '48731'
                    }, '\n                ', this.out_of_stock ? 'Sold out' : 'Select options', '\n              ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '51511'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': this.set_qty || 1,
                        'key': '51513'
                    }),
                    _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock,
                        'key': '51515'
                    }, this.out_of_stock ? ['Sold out'] : null, !this.out_of_stock ? [
                        '\n                  Add to cart',
                        this.set_qty ? ` Set of ${ this.set_qty }` : '',
                        '\n                '
                    ] : null)
                ] : null))), _createElement('div', { 'className': 'product-actions' }, _createElement('div', { 'className': 'product-action' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '61181'
                    }, '\n                ', this.out_of_stock ? 'Sold out' : 'Select options', '\n              ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '63961'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '63963'
                    }),
                    _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock,
                        'key': '63965'
                    }, '\n                ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n              ')
                ] : null)), _createElement('button', {
                    'className': 'wishlist btn product-btn wishlist_mb',
                    'data-icon-wishlist': true,
                    'data-product-handle': this.handle,
                    'data-id': this.id
                }, _createElement('span', { 'className': 'add' }, 'Add to Wish List'), _createElement('span', { 'className': 'remove' }, 'Remove Wish List'))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products product-recommendations__items' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'halo-row halo-row-custom column-2 cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []