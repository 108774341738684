export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  GARAGE_BUTTON_CLASS: 'navUser-text-wrapper',
  GARAGE_SIZE: '<span class="garage-size countPill cart-quantity" key="garage-size">{{size}}</span>',

  PRODUCT_REPEATER_CLASS: 'halo-column halo-column-product',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SEARCH_BOX_BUTTON_CLASS: 'btn-search search-submit',
  SEARCH_BOX_CLASS: 'search-bar',
  SEARCH_BOX_INPUT_CLASS: 'input-group-field',

  FACET_BAR_WHEELS_TIRES_DROPDOWNS_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  RELATED_PRODUCT_CONTAINER_CLASS: 'product-recommendations__items',
  RELATED_PRODUCT_LIST_CLASS: 'halo-row halo-row-custom column-2',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
